import React, { useState, useEffect } from "react";

export const ProgressBar = ({ width, percent }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(percent);
    }, [percent]);

    return (
        <div className="relative pt-1">
            <div className="overflow-hidden h-1 mt-1 text-xs flex bg-transparent">
                <div style={{ width: `${value}%` }} className="progress shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary" />
            </div>
        </div>
    );
};

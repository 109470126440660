import React from "react";

const PhoneNumber = ({number, link, show}) => {
    return (
        <div className={`mt-2 text-center transition-opacity duration-700 opacity-0 ${show ? 'opacity-100' : ''}`}>
            <span className="block text">Benötigen Sie Hilfe? Rufen Sie uns kostenlos an!</span>
            <span className="h3 block phone"><a href={link}>{number}</a></span>
        </div>
    )
}

PhoneNumber.defaultProps = {
    number: "0800 0009 902",
    link: "tel:+498000009902",
    show: true
}

export default PhoneNumber

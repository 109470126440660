import React from "react";

const Bubble = ( { handleClick, image, showBubble } ) => {
    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            this.focus()
        }
    }

    return (
        <div className={`video-bubble rounded-full h-10 w-10 flex items-center justify-center shadow-lg ${showBubble ? 'block' : 'hidden'}`} style={{ backgroundImage: `url(${image})` }} onKeyDown={(e) => handleKeyPress(e)} onClick={handleClick} role="button" tabIndex={0}>
            <span className={`text-white text-sm font-semibold text-center`}>Jetzt vergleichen</span>
        </div>
    )
}

export default Bubble

import React, { useState, useRef, useEffect, useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useKeenSlider } from "keen-slider/react";

// Images
import HeroBackgroundImage from "../images/hero.jpg";
import BubbleImage from "../images/mitarbeiter.jpg";

// Components
import Wrapper from "../components/Wrapper";
import Seo from "../components/Seo";
import Modal from "../components/Modal";
import Bubble from "../components/Bubble";
import PhoneNumber from "../components/PhoneNumber";

// Icons
import { StarFill, ArrowLeft, ArrowRight } from "../components/icons/SvgIcons";

// React-Store
import { Context } from "../utils/store";

const IndexContent = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pause, setPause] = useState(false);
    const timer = useRef();
    const [state, dispatch] = useContext(Context);

    let showModal = false;

    if(state.show_modal !== undefined) {
        showModal = state.show_modal
    }

    // Content
    const random = '5650';

    // Slider
    const [sliderRef, slider] = useKeenSlider({
        initial: 0,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide)
        },
        spacing: 10,
        duration: 1000,
        slidesPerView: 1,
        loop: true,
        breakpoints: {
            "(min-width: 640px)": {
                slidesPerView: 2,
            },
            "(min-width: 1024px)": {
                slidesPerView: 3,
            },
        },
        dragStart: () => {
            setPause(true)
        },
        dragEnd: () => {
            setPause(false)
        },
    })

    // Slider Hover
    useEffect(() => {
        sliderRef.current.addEventListener("mouseover", () => {
            setPause(true)
        })
        sliderRef.current.addEventListener("mouseout", () => {
            setPause(false)
        })
    }, [sliderRef])

    // Slider Autoplay
    useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next()
            }
        }, 5000)
        return () => {
            clearInterval(timer.current)
        }
    }, [pause, slider])

    // Styles
    const headerBackground = {
        backgroundImage: `url(${HeroBackgroundImage})`
    }

    return (
        <>
            <Bubble handleClick={() => dispatch({type: 'SHOW_MODAL', payload: true})} image={BubbleImage} showBubble={!showModal} />

            <section className={`hero-section bg-hero bg-center bg-cover relative z-50`} style={headerBackground}>
                <div className={`section-inner container mx-auto`}>
                    <div className={`grid grid-cols-1 md:grid-cols-2 gap-2 items-center`}>
                        <div className={`relative md:col-span-2`}>
                            <Modal showModal={showModal} />
                        </div>
                    </div>
                </div>
            </section>

            <section className={`bg-primary sep py-4`}>
                <div className={`section-inner container mx-auto`}>
                    <div className={`grid grid-cols-1 md:grid-cols-3 gap-4 items-center`}>
                        <div className={`text-center`}>
                            <h5 className="text-white">Kostenlos & unverbindlich</h5>
                        </div>
                        <div className={`text-center`}>
                            <h5 className="text-white">Regionale Anbieter</h5>
                        </div>
                        <div className={`text-center`}>
                            <h5 className="text-white">Sicher, einfach & schnell</h5>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`bg-white sep py-8`}>
                <div className={`section-inner container mx-auto`}>
                    <div className={`grid grid-cols-1 gap-4 items-center mb-8`}>
                        <div className={`text-center`}>
                            <h2>So funktionierts</h2>
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 items-center`}>
                        <div className={`card text-center mb-5 md:mb-0`}>
                            <StaticImage
                                src="../images/icons/klicken.svg"
                                width={90}
                                height={100}
                                alt="A Gatsby astronaut"
                                className={`mx-auto mb-3`}
                            />
                            <h3 className={`h5 mb-2`}>Auswählen</h3>
                            <p>Um das richtige Elektromobil-Angebot für Sie zu finden, beantworten Sie schnell und einfach die Fragen in unserem Konfigurator.</p>
                        </div>
                        <div className={`card text-center mb-5 md:mb-0`}>
                            <StaticImage
                                src="../images/icons/wahlen.svg"
                                width={90}
                                height={100}
                                alt="A Gatsby astronaut"
                                className={`mx-auto mb-3`}
                            />
                            <h3 className={`h5 mb-2`}>Auswählen</h3>
                            <p>Anhand Ihrer Antworten suchen wir die Anbieter mit den besten Konditionen aus.</p>
                        </div>
                        <div className={`card text-center mb-5 md:mb-0`}>
                            <StaticImage
                                src="../images/icons/freude.svg"
                                width={90}
                                height={100}
                                alt="A Gatsby astronaut"
                                className={`mx-auto mb-3`}
                            />
                            <h3 className={`h5 mb-2`}>Auswählen</h3>
                            <p>Sie erhalten unverbindliche Informationen und auf Wunsch eine Probefahrt mit dem für Sie passenden Anbieter.</p>
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 gap-4 items-center`}>
                        <div className={`text-center`}>
                            <button type="button" onClick={() => dispatch({type: 'SHOW_MODAL', payload: true})}  className={`btn btn-lg btn-secondary`}>Jetzt vergleichen und sparen</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`bg-white sep py-8`}>
                <div className={`section-inner container mx-auto`}>
                    <div className={`grid grid-cols-1 gap-4 items-center mb-8`}>
                        <div className={`text-center`}>
                            <h2>Wir haben verglichen</h2>
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 gap-4 items-center mb-8`}>
                        <div className="testimonial-slider">
                            <div ref={sliderRef} className="keen-slider">
                                <div className={`keen-slider__slide`}>
                                    <div className={`testimonial text-center`}>
                                        <div className={`content p-2`}>
                                            <div className="body font-medium mb-2 text-2xl">
                                                <p>Endlich ein Anbieter der die Preise ordentlich vergleicht und mir das Angebot mit dem besten Preis-Leisuntgsverhältnis zukommen hat lassen. Danke.</p>
                                            </div>
                                            <header>
                                                <span className={`h5 leading-none block`}>Hannelore G.</span>
                                                <span className={`block text-sm`}>aus Siegen</span>
                                            </header>
                                        </div>
                                    </div>
                                </div>

                                <div className={`keen-slider__slide`}>
                                    <div className={`testimonial text-center`}>
                                        <div className={`content p-2`}>
                                            <div className="body font-medium mb-2 text-2xl">
                                                <p>Kostenloses Angebot, kostenlose Probefahrt und ich hatte genügend Bedenkzeit um mich in Ruhe zu entscheiden. Seit zwei Wochen bin ich endlich wieder mobil.</p>
                                            </div>
                                            <header>
                                                <span className={`h5 leading-none block`}>Franz S.</span>
                                                <span className={`block text-sm`}>aus Herford</span>
                                            </header>
                                        </div>
                                    </div>
                                </div>

                                <div className={`keen-slider__slide`}>
                                    <div className={`testimonial text-center`}>
                                        <div className={`content p-2`}>
                                            <div className="body font-medium mb-2 text-2xl">
                                                <p>Qualität und Service der Fahrzeuge hat mich überzeugt und die Gespräche waren sehr angenehm.</p>
                                            </div>
                                            <header>
                                                <span className={`h5 leading-none block`}>Herbert T.</span>
                                                <span className={`block text-sm`}>aus Düsseldorf</span>
                                            </header>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {slider && (
                                <>
                                    <ArrowLeft
                                        onClick={(e) => e.stopPropagation() || slider.prev()}
                                        disabled={currentSlide === 0}
                                    />
                                    <ArrowRight
                                        onClick={(e) => e.stopPropagation() || slider.next()}
                                        disabled={currentSlide === slider.details().size - 1}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 gap-4 items-center`}>
                        <div className={`text-center`}>
                            <div className="stars">
                                <StarFill />
                                <StarFill />
                                <StarFill />
                                <StarFill />
                                <StarFill />
                            </div>
                            <p className="text">
                                Bereits {random} erfolgreiche Vergleiche.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={`bg-white py-8`}>
                <div className={`section-inner container mx-auto`}>
                    <div className={`grid grid-cols-1 gap-4 items-center mb-8`}>
                        <div className={`text-center`}>
                            <h2>Ihre Ansprechpartner – Wir für Sie</h2>
                        </div>
                    </div>

                    <div className={`grid grid-cols-2 md:grid-cols-4 gap-4 mb-8 items-center`}>
                        <div className={`card mb-5 md:mb-0 col-span-2 md:mr-auto text-center md:text-left`}>
                            <h3 className={`mb-2`}>Vertrauen ist das A&O</h3>
                            <p>Der Erwerb eines Elektromobils geht weit über die reine Anschaffung hinaus. Die richtige Beratung, der Vergleich verschiedener Angebote, sowie ein umfangreiches Serviceversprechen vor, während und nach Ihrer Entscheidung sind für uns selbstverständlich. Mit mehr als 10 Jahren Erfahrung beraten wir Sie in Ihrem besten Interesse, verstehen Ihre Bedürfnisse nach Mobilität und helfen Ihnen diese zu erreichen. Von Menschen für Menschen lautet dabei unser Motto, deswegen dürfen Sie sich jetzt schon auf unseren persönlichen Service freuen.</p>
                        </div>
                        <div className={`card col-span-2 text-center`}>
                            <StaticImage
                                src="../images/mitarbeiter.jpg"
                                width={816}
                                height={514}
                                alt="A Gatsby astronaut"
                                className={`mx-auto mb-3 w-full`}
                            />
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 gap-4 mb-4 items-center`}>
                        <div className={`text-center`}>
                            <button type="button" onClick={() => dispatch({type: 'SHOW_MODAL', payload: true})} className={`btn btn-lg btn-secondary`}>Jetzt vergleichen und sparen</button>
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 gap-4 items-center`}>
                        <div className={`text-center`}>
                            <PhoneNumber />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const Home = () => {
    return (
        <Wrapper>
            <Seo title="Elektromobilvergleich" description="Vergleichen und bis zu 3.600€ sparen!"/>
            <IndexContent />
        </Wrapper>
    )
}

export default Home

import React from "react";
import sprite from "../../images/icon-sprite.png";

const pannelButtonStyles = {
    backgroundImage: `url(${sprite})`
}

export const PanelButton = ( { handleInput, value, name, image, classes } ) => {
    return (
        <button type="button" className={`panel-btn relative` + (classes ? ' ' + classes : '')} onClick={handleInput} value={value} name={name}>
            <div className="panel-btn-image-wrap mb-2 relative">
                <div className={`panel-btn-image mx-auto absolute-center ${image}`} style={pannelButtonStyles} />
            </div>
            <span className={`block text-center text-sm md:text-base`}>{value}</span>
        </button>
    )
}

export default PanelButton;
